<template>
  <div v-show="show" class="Clickable" @click="$emit('displaysQuote')">
    <video
      ref="myVideo"
      v-show="canPlayVideo"
      class="Clickable_video"
      autoplay
      loop
      muted
    >
      <source :src="pastilleVideo" type="video/webm" />
    </video>
    <div v-show="!canPlayVideo" class="Clickable_video_Alt">
      <div ref="myImg" class="Clickable_Animation">
        <div
          v-show="imgHeight"
          class="Clickable_Animation_Halo"
          :style="{ width: `${imgHeight}px` }"
        ></div>
      </div>
      <img class="Clickable_img" :src="pastilleImg" />
    </div>
  </div>
</template>

<script>
import gsap from 'gsap'

export default {
  name: 'Clickable',
  props: {},
  data () {
    return {
      intervalTimer: 0,
      canPlayVideo: true,
      show: false,
      imgHeight: 0
    }
  },
  computed: {
    isSafari () {
      return (
        navigator.userAgent.indexOf('Safari') !== -1 &&
        navigator.userAgent.indexOf('Chrome') === -1
      )
    },
    wording () {
      return this.$store.getters['langs/wording']
    },
    settings () {
      return this.$store.getters['events/getEvents'].default.settings
        .custom_config
    },
    pastilleVideo () {
      const url = this.wording.relaxation_pastille.media['en-US'][0].objects
        .original
      return url
    },
    pastilleImg () {
      const url = this.wording.relaxation_pastilleAlt.media['en-US'][0].objects
        .original
      return url
    }
  },
  mounted () {
    this.intervalTimer = setTimeout(() => {
      this.show = true
      const video = this.$refs.myVideo
      this.canPlayVideo =
        video.canPlayType('video/webm; codecs="vp8, vorbis"') && !this.isSafari
      this.$nextTick(() => {
        if (!this.canPlayVideo) {
          this.imgHeight = this.$refs.myImg.clientHeight
          this.handleAnimation()
        }
      })
    }, this.settings.delayClick)
  },
  beforeDestroy () {
    clearTimeout(this.intervalTimer)
  },
  methods: {
    handleAnimation () {
      const tl = gsap.timeline({ repeat: -1, repeatDelay: 0.7 })
      tl.fromTo(
        '.Clickable_Animation_Halo',
        {
          scaleX: 0.2,
          scaleY: 0.2,
          opacity: 1
        },
        {
          duration: 0.3,
          scaleX: 0.4,
          scaleY: 0.4,
          opacity: 1,
          ease: 'power2.in'
        }
      )
      tl.to('.Clickable_Animation_Halo', {
        duration: 0.3,
        scaleX: 0.5,
        scaleY: 0.5,
        opacity: 0
      })
      tl.fromTo(
        '.Clickable_Animation_Halo',
        {
          scaleX: 0.3,
          scaleY: 0.3,
          opacity: 0.5
        },
        {
          delay: 0,
          duration: 0.2,
          scaleX: 0.4,
          scaleY: 0.4,
          opacity: 0.8
        }
      )
      tl.to('.Clickable_Animation_Halo', {
        duration: 0.5,
        scaleX: 0.5,
        scaleY: 0.5,
        opacity: 0
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.Clickable {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
}
video,
.Clickable_video_Alt {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10vw;
  width: 10vw;
  @media (orientation: portrait) {
    width: auto;
    height: 15%;
  }
}

.Clickable_img {
  height: 50%;
  width: 50%;
  object-fit: contain;
}

.Clickable_Animation {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.Clickable_Animation_Halo {
  opacity: 0;
  border: rgba(255, 255, 255, 0.5) solid 2px;
  border-radius: 100%;
  height: 100%;
  margin: 0 auto;
}
</style>
