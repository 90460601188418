<template>
  <div class="Diaporama">
    <swiper
      ref="mySwiper"
      class="Swiper"
      :options="swiperOptions"
      @slideChangeTransitionEnd="onSlideEndChange()"
    >
      <swiper-slide v-for="(image, index) in images" :key="index">
        <div class="Diaporama-slide">
          <img
            class="Diaporama-img"
            :src="GetImageURL(image)"
            :style="{
              height: imageH,
              width: `${imageW}px`,
              transform: `translate(${x}, ${y})`
            }"
          />
          <!-- <adaptative-image class="Diaporama-img" :obj="image" /> -->
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
// import AdaptativeImage from '../../../components/adaptativeImage/AdaptativeImage.vue'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import gsap from 'gsap'

export default {
  name: 'Diaporama',
  components: {
    Swiper,
    SwiperSlide
  },
  props: {
    imageKeys: Array
  },
  data () {
    return {
      intervalTimer: 0,
      activeIndex: 0,
      show: false,
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth,
      imageH: 'auto',
      imageW: 'auto',
      x: 0,
      y: 0,
      zoomin: true
    }
  },
  watch: {
    displaysTuto: function (newVal) {
      if (!newVal) {
        this.zoomIn()
        this.swiper.autoplay.start()
      }
    },
    windowHeight (newHeight, oldHeight) {
      const txt = `it changed to ${newHeight} from ${oldHeight}`
      return txt
    },
    windowWidth (newWidth, oldWidth) {
      const txt = `it changed to ${newWidth} from ${oldWidth}`
      return txt
    }
  },
  computed: {
    displaysTuto () {
      return this.$store.getters['session/displaysTuto']
    },
    wording () {
      return this.$store.getters['langs/wording']
    },
    swiper () {
      return this.$refs.mySwiper.$swiper
    },
    maxIndex () {
      return this.imageKeys.length - 1
    },
    settings () {
      return this.$store.getters['events/getEvents'].default.settings
        .custom_config
    },
    images () {
      const images = this.imageKeys.map(key => {
        return this.wording[key]
      })
      return images
    },
    swiperOptions () {
      return {
        speed: this.settings.diaporama.speed,
        autoplay: {
          delay: this.settings.diaporama.interval
        },
        loop: true,
        allowTouchMove: false,
        effect: 'fade',
        fadeEffect: {
          crossFade: true
        }
      }
    }
  },
  mounted () {
    this.onResize()
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
      if (!this.displaysTuto) this.zoomIn()
      else this.swiper.autoplay.stop()
    })
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    async onResize () {
      const processed = await this.addImageProcess(
        this.GetImageURL(this.images[this.activeIndex])
      )
      const screenRatio = window.innerWidth / window.innerHeight
      const imageRatio = processed.width / processed.height
      this.imageW = processed.width
      if (screenRatio > imageRatio) {
        this.imageW = window.innerWidth
        this.imageH = window.innerHeight
      } else {
        const w = window.innerHeight * imageRatio
        this.imageW = w
        this.imageH = w / imageRatio
      }
      this.x = `-${(this.imageW - window.innerWidth) / 2}px`
      this.y = `-${(this.imageH - window.innerHeight) / 2}px`
    },
    async addImageProcess (src) {
      return new Promise(resolve => {
        const img = new Image()
        img.onload = () => resolve(img)
        img.src = src
      })
    },
    GetImageURL (image) {
      return image.media['en-US'][0].objects.original
    },
    zoomOut () {
      gsap.to('.swiper-slide-active .Diaporama-slide', {
        duration:
          (this.settings.diaporama.speed + this.settings.diaporama.interval) /
          1000,
        scale: 1
      })
    },
    zoomIn () {
      gsap.to('.swiper-slide-active .Diaporama-slide', {
        duration:
          (this.settings.diaporama.speed + this.settings.diaporama.interval) /
          1000,
        scale: 1.1
      })
    },
    onSlideEndChange () {
      if (this.activeIndex < this.maxIndex) {
        this.activeIndex = this.activeIndex + 1
      } else {
        this.zoomin = !this.zoomin
        this.activeIndex = 0
      }
      if (this.zoomin) this.zoomIn()
      else this.zoomOut()
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.Diaporama {
  width: 100%;
  height: 100%;
}

.Swiper,
.Diaporama-slide {
  width: 100%;
  height: 100%;
}

.Diaporama-img {
  // height: 100%;
  // @media (orientation: landscape) {
  //   width: 100%;
  //   height: auto;
  // }
  // @media (orientation: portrait) {
  //   height: 100%;
  //   width: auto;
  // }
  opacity: 1;
}
</style>
