<template>
  <div class="Quote">
    <div class="Quote_Container">
      <p>{{ `"${$t(currentQuote)}"` }}</p>
      <img
        :src="require('../../../assets/pantelleria/signature-blanche.svg')"
      />
    </div>
  </div>
</template>

<script>
import { translateX, animOpacity } from '@/utils/animations.js'

export default {
  name: 'Quote',
  props: {
    indexQuote: Number
  },
  data () {
    return {
      desaparitionTimer: 0
    }
  },
  computed: {
    wording () {
      return this.$store.getters['langs/wording']
    },
    settings () {
      return this.$store.getters['events/getEvents'].default.settings
        .custom_config
    },
    currentQuote () {
      const key = Object.keys(this.$store.getters['langs/wording']).filter(el =>
        el.startsWith('relaxation_quote')
      )[this.indexQuote]
      return key
    }
  },
  mounted () {
    const longerTimer =
      this.settings.quoteAnim.opacityInDuration >=
      this.settings.quoteAnim.translateXDuration
        ? this.settings.quoteAnim.opacityInDuration
        : this.settings.quoteAnim.translateXDuration
    this.appearsAnim()
    this.desaparitionTimer = setTimeout(() => {
      this.disappearsAnim()
    }, longerTimer + this.settings.quoteAnim.pauseDuration)
  },
  beforeDestroy () {
    clearTimeout(this.desaparitionTimer)
  },
  methods: {
    appearsAnim () {
      animOpacity(
        '.Quote_Container',
        this.settings.quoteAnim.opacityInDuration / 1000,
        0,
        1
      )
      translateX(
        '.Quote_Container',
        this.settings.quoteAnim.translateXDuration / 1000,
        '20vw'
      )
    },
    disappearsAnim () {
      animOpacity(
        '.Quote_Container',
        this.settings.quoteAnim.opacityOutDuration / 1000,
        1,
        0,
        () => this.$emit('endQuote')
      )
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '../../../styles/main.scss';

.Quote {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
}

.Quote_Container {
  max-width: 75%;
  @include fluid-type($min_width, $max_width, $min_font * 1.9, $max_font * 2.5);
  img {
    width: 40%;
  }
}

p {
  font-family: 'BaronDidotT', Helvetica, Arial, sans-serif;
  color: white;
  text-align: center;
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.4), 0px 8px 13px rgba(0, 0, 0, 0.1),
    0px 18px 23px rgba(0, 0, 0, 0.1);
  margin-bottom: 2%;
}
</style>
