<template>
  <div class="Room">
    <div class="Header">
      <div class="Header_Content">
        <img class="Header_img" alt="Armani logo" :src="headerLogoImg" />
      </div>
    </div>
    <div v-if="$store.getters['session/displaysTuto']">
      <modale-tuto
        :text="$t('pantelleria.txt')"
        :logo="logoTuto"
        @closeTutoModale="closeTutoModale"
      />
    </div>
    <div class="Diaporama_Container">
      <diaporama :imageKeys="imagesDiapoKeys" />
      <clickable v-if="clickable" @displaysQuote="displaysQuote()" />
      <quote v-if="showQuote" :indexQuote="indexQuote" @endQuote="endQuote()" />
    </div>
  </div>
</template>

<script>
import Diaporama from '../../components/Diaporama'
import Clickable from '../../components/Clickable'
import Quote from '../../components/Quote'
import ModaleTuto from '@/components/modale/ModaleTuto'
import { Howl } from 'howler'

const soundClick = require('../../assets/audios/click.mp3')

export default {
  name: 'relaxRoom',
  components: { Diaporama, Clickable, Quote, ModaleTuto },
  data () {
    return {
      clickable: true,
      showQuote: false,
      indexQuote: 0,
      diapoIndex: 0,
      howl: null,
      howlDiapo: null,
      startsSound: false
    }
  },
  computed: {
    logoTuto () {
      return this.wording.pantelleria.logo.media['en-US'][0].objects.original
    },
    wording () {
      return this.$store.getters['langs/wording']
    },
    imagesDiapoKeys () {
      const keys = Object.keys(this.wording).filter(el =>
        el.startsWith('relaxation_diapo')
      )
      return keys
    },
    headerLogoImg () {
      const url = this.wording.relaxation_logo.media['en-US'][0].objects
        .original
      return url
    },
    headerExitImg () {
      const url = this.wording.relaxation_exit.media['en-US'][0].objects
        .original
      return url
    },
    maxIndexQuote () {
      const quotesKeys = Object.keys(this.wording).filter(el =>
        el.startsWith('relaxation_quote')
      )
      return quotesKeys.length - 1
    },
    audiosDiapo () {
      const keys = Object.keys(this.wording).filter(el =>
        el.startsWith('relaxation_diapo')
      )
      const audiosUrl = keys.map(key => {
        return this.wording[key].audio.media['en-US'][0].objects.original
      })
      return audiosUrl
    }
  },
  created () {
    const displaysTuto =
      !this.$store.getters['session/getAlreadySeenTutos'].includes(
        this.$route.name
      ) || this.$store.getters['session/displaysTuto']
    this.$store.dispatch('session/displaysTuto', displaysTuto)
  },
  mounted () {
    this.initHowler() // prepare songs
  },
  beforeDestroy () {
    // destroy Howl objects
    this.howl.unload()
    this.howlDiapo.unload()
  },
  methods: {
    GetImageURL (image) {
      return image.media['en-US'][0].objects.original
    },
    initHowler () {
      this.howl = new Howl({
        src: [soundClick],
        html5: true,
        volume: 0.5
      })
      this.howlDiapo = new Howl({
        src: [this.audiosDiapo[0]],
        html5: true,
        volume: 0.5,
        loop: true
      })
    },
    playsDiapoSound () {
      this.howlDiapo.play()
    },
    displaysQuote () {
      this.howl.play() // plays sound tap on light
      this.clickable = false
      this.showQuote = true
      if (!this.startsSound) {
        this.startsSound = true
        this.playsDiapoSound() // start sound diaporama
      }
    },
    endQuote () {
      this.showQuote = false
      this.clickable = true
      if (this.indexQuote === this.maxIndexQuote) this.indexQuote = 0
      else this.indexQuote = this.indexQuote + 1
    },
    leaveRoom () {
      this.$router.push({ name: 'lobby' })
    },
    closeTutoModale () {
      this.$store.dispatch('session/addSeenTuto', this.$route.name)
      this.$store.dispatch('session/displaysTuto', false)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../../styles/main.scss';

img,
video {
  pointer-events: none;
}

.Room {
  font-weight: 500;
  position: relative;
  height: 100%;
  width: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: white;
}

.Diaporama_Container {
  position: relative;
  width: 100%;
  height: 100%;
}

.Diaporama_Container_Bg {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.Header {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  width: 100%;
  height: 8vh;
  box-sizing: border-box;
  padding-top: 1%;
  z-index: 2;
  @media (max-aspect-ratio: 16/9) and (orientation: landscape) {
    height: 6vh;
  }
  @media (orientation: portrait) {
    padding-top: 2%;
  }
  @media only screen and (max-width: 680px) and (orientation: portrait) {
    height: 6vh;
  }
}

.Header_Content {
  position: relative;
  width: 100%;
  height: 100%;
}

.Header_icon {
  position: absolute;
  left: 2%;
  height: 50%;
  img {
    object-fit: contain;
    height: 100%;
  }
}

.Header_img {
  object-fit: contain;
  height: 100%;
}
</style>
