<template>
  <div class="ModaleTuto_Container">
    <div class="ModaleTuto">
      <div class="ModaleTuto__Blur" />
      <div
        class="ModaleTuto__Header"
        ref="myHeader"
        :style="{ height: `${imageHeight}px` }"
      >
        <img
          :src="logo"
          ref="myImg"
          :style="{ left: `${centerImageLeft}px` }"
        />
      </div>
      <p class="text">{{ text }}</p>
      <div class="ModaleTuto__Footer">
        <CTA
          class="ModaleTuto__Btn"
          :label="$t('ready').toUpperCase()"
          @clickButton="onClick"
          :color="$store.getters['theme/theme'].secondary"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { animOpacity } from '@/utils/animations.js'
import CTA from '@/components/button/CTA'

export default {
  name: 'modale-tuto',
  components: { CTA },
  props: {
    text: {
      type: String,
      default: ''
    },
    logo: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      centerImageLeft: 0,
      imageHeight: 0
    }
  },
  computed: {
    wording () {
      return this.$store.getters['langs/wording']
    }
  },
  mounted () {
    this.centerImageLeft =
      (this.$refs.myHeader.clientWidth - this.$refs.myImg.clientWidth) / 2
    setTimeout(() => {
      this.imageHeight = this.$refs.myImg.clientHeight
      this.open()
    }, 700) // trick update imageHeight. nextTick does not work
  },
  beforeDestroy () {},
  methods: {
    open () {
      animOpacity('.ModaleTuto_Container', 0.3, 0, 1)
    },
    onClick () {
      animOpacity('.ModaleTuto_Container', 0.3, 1, 0, () =>
        this.$emit('closeTutoModale')
      )
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '../../styles/main.scss';

.ModaleTuto_Container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  box-sizing: border-box;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9;
  opacity: 0;
  @media only screen and (max-width: 500px) {
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.ModaleTuto {
  position: relative;
  box-sizing: border-box;
  padding: 1.5rem;
  width: 406px;
  min-height: 300px;
  border-radius: 30px;
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: rgba(255, 255, 255, 0.4);
  color: black;
  @media only screen and (max-width: 500px) {
    padding: 2.5rem 1.5rem;
    width: 100%;
  }
}

.ModaleTuto__Content {
  width: 100%;
  height: 1px;
}

.ModaleTuto__Blur {
  border-radius: 30px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  backdrop-filter: blur(10px);
}

.ModaleTuto__Header {
  // safari weird behavior
  width: 100%;
  position: relative;
  img {
    position: absolute;
    top: 0;
    width: 80%;
    object-fit: contain;
  }
}

.text {
  margin-top: 2rem;
  margin-bottom: 2rem;
  line-height: 130%;
  text-align: center;
  font-family: 'Gotham Book';
  @include fluid-type($min_width, $max_width, $min_font * 1.2, $max_font * 0.8);
}

.ModaleTuto__Footer {
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ModaleTuto__Btn {
  margin: 0 auto;
  height: 100%;
  width: 55%;
  @media only screen and (max-width: 500px) {
    width: 75%;
  }
}
</style>
